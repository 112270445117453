<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <span class="modal-card-title">View Logs of {{ batchJob.Id }}</span>
        <button class="delete" aria-label="close" v-on:click="Close"></button>
      </header>
      <section class="modal-card-body">
        <!-- Content ... -->
        <table class="table">
          <thead>
            <tr>
              <th>CreatedAt</th>
              <th>Type</th>
              <th>Message</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(l, index) in batchJob.Logs" :key="index">
              <td>{{l.CreatedAt}}</td>
              <td>
                <span class="tag is-info">{{l.TypeAsString}}</span>
              </td>
              <td :title="l.Data" style="word-break:break-word">{{l.Data.Message}}</td>
              <td>
                <span v-if="l.StatusAsString == 'Success'" class="tag is-success">Success</span>
                <span v-else class="tag is-warning">{{l.StatusAsString}}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "batchJobLogDialog",
  props: {
    batchJob: Object
  },
  methods: {
    Close: function() {
      //emit close!
      this.$emit("close");
    }
  }
};
</script>
